import { render, staticRenderFns } from "./wsSelect.vue?vue&type=template&id=61f333ae&scoped=true&"
import script from "./wsSelect.vue?vue&type=script&lang=js&"
export * from "./wsSelect.vue?vue&type=script&lang=js&"
import style0 from "./wsSelect.vue?vue&type=style&index=0&id=61f333ae&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61f333ae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VAutocomplete,VDivider,VHover,VIcon,VSelect,VSheet})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Ripple})
